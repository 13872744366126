import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSearch } from './search-provider';
import SCOPE_TYPES from '../../utils/sentryScopeTypes';
import { getEmptyAlgoliaSearch } from '../../pages/api';

const SearchResultsContext = createContext();

export const useSearchResults = () => {
  const context = useContext(SearchResultsContext);
  if (!context) {
    throw new Error('useSearchResults must be used within a SearchResultsProvider');
  }
  return context;
};

export const SearchResultsProvider = ({ children }) => {
  const { state: { query } } = useSearch();
  const [searchData, setSearchData] = useState({
    popular: [],
    read: [],
    isLoading: false
  });

  useEffect(() => {
    let mounted = true;

    const _getEmptyAlgoliaSearch = () =>
      getEmptyAlgoliaSearch({
        uri: `/api/algolia/empty-search`,
        scopes: [SCOPE_TYPES.SERVICES.REST_API]
      });

    const fetchSearchData = async () => {
      if (!mounted) return;
      setSearchData(prev => ({ ...prev, isLoading: true }));
      
      const emptyAlgoliaSearch = await _getEmptyAlgoliaSearch();

      if (!mounted) return;

      if(emptyAlgoliaSearch?.data?.results[1]?.hits && emptyAlgoliaSearch?.data?.results[2]?.hits) {
        setSearchData({
          popular: emptyAlgoliaSearch?.data?.results[1]?.hits,
          read: emptyAlgoliaSearch?.data?.results[2]?.hits,
          isLoading: false
        });
      } else {
        setSearchData({
          popular: [],
          read: [],
          isLoading: false
        });
      }
    };

    if (!query && !searchData.popular.length && !searchData.read.length) {
      fetchSearchData();
    }

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <SearchResultsContext.Provider value={searchData}>
      {children}
    </SearchResultsContext.Provider>
  );
};

SearchResultsProvider.propTypes = {
  children: PropTypes.node.isRequired
}; 