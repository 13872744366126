import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import qs from 'qs';
import { connect, useStore } from 'react-redux';

// Components
import PopularSearches from '../../organisms/algolia-popular';
import ReadSearches from '../../organisms/algolia-read';
import Recent from '../../organisms/algolia-recent';
import SuggestedResults from '../../organisms/algolia-suggested';
import SearchBox from '../../organisms/algolia-search-box';
import Results from '../../organisms/algolia-results';
import NoResults from '../../organisms/algolia-no-results';

// Context
import { RecentProvider } from '../../utils/context/recent-provider';
import { useSearch } from '../../utils/context/search-provider';

// Hooks
import useEventListener from '../../utils/hooks/useEventListener';
import { toggleGlobalSearchVisibility } from '../../store/modules/interfaces/actions';

// Utils
import { logAmplitude } from '../../utils/amplitude';
import searchClient from '../../utils/algolia';

const Left = styled.div(() => ({
  gridArea: 'left'
}));

const Right = styled.div(({ theme }) => ({
  gridArea: 'right',
  marginTop: theme.modularScale.large,
  [`@media screen and (min-width: ${theme.breakpoint.small})`]: {
    marginTop: 0
  },
  [`@media screen and (min-width: ${theme.breakpoint.medium})`]: {
    overflow: 'auto',
    paddingBottom: theme.modularScale.xlarge
  },
  [`@media screen and (min-width: ${theme.breakpoint.large})`]: {
    display: 'grid',
    gridTemplateColumns: 'auto 22vw',
    gridGap: '10px',
    paddingBottom: theme.modularScale.large,
    overflow: 'unset'
  }
}));

const Panel = styled.div(({ isOpened, theme }) => ({
  display: isOpened ? 'flex' : 'none',
  flexDirection: 'column',
  [`@media screen and (min-width: ${theme.breakpoint.small})`]: {
    background: '#ffffff',
    display: isOpened ? 'grid' : 'none',
    gridGap: '3rem',
    gridTemplateAreas: '"left right"',
    gridTemplateColumns: '250px auto',
    padding: '2rem',
    position: 'absolute',
    width: '100%'
  },
  [`@media screen and (min-width: ${theme.breakpoint.medium})`]: {
    left: 0,
    top: '68px',
    height: '100vh',
    padding: '32px 6.5vw'
  }
}));

const Wrapper = styled.div(({ isOpened, theme }) => ({
  margin: '0 auto',
  padding: '0 2rem 1rem 2rem',
  width: '100%',
  [`@media screen and (min-width: ${theme.breakpoint.small})`]: {
    padding: '0 0 1rem 0'
  },
  ...(
    isOpened && ({
      background: '#ffffff',
      bottom: 0,
      left: 0,
      overflow: 'auto',
      position: 'fixed',
      right: 0,
      top: 0,
      width: '100%',
      padding: theme.modularScale.medium,
      zIndex: theme.layers.balcony,
      [`@media screen and (min-width: ${theme.breakpoint.small})`]: {
        padding: '0 0 1rem 0'
      },
      [`@media screen and (min-width: ${theme.breakpoint.medium})`]: {
        background: 'transparent',
        position: 'static'
      }
    })
  )
}));

const RecentSearches = styled(Recent)(({ theme, mobileOnly }) => ({
  display: mobileOnly ? 'block' : 'none',
  marginBottom: theme.modularScale.large,
  [`@media screen and (min-width: ${theme.breakpoint.small})`]: {
    display: mobileOnly ? 'none' : 'block'
  }
}));

const SearchResultsContainer = (props) => {
  const router = useRouter();
  const store = useStore();
  const [searchQuery, setQuery] = useState('');
  const [searchState, setSearchState] = useState({ query: '' });
  const { state: { isOpened }, close } = useSearch();

  const onSearchStateChange = (updatedSearchState) => {
    setSearchState(updatedSearchState);
    const { query } = updatedSearchState;
    setQuery(query);
  };

  // Prevent Algolia API calls when there's no search query
  const customSearchClient = {
    ...searchClient,
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
          })),
        });
      }
      return searchClient.search(requests);
    },
  };

  const closeSearch = () => {
    props.toggleGlobalSearchVisibility(false);
    logAmplitude('Closed Search');
    close();
  };

  useEventListener('keyup', ({ code }) => {
    if (!isOpened || code !== 'Escape') return;
    closeSearch();
  });

  useEventListener('click', (e) => {
    if (isOpened && !e.target.closest('#search-modal')) closeSearch();
  });

  // search for w in URL and populate input
  const handleRouteChange = (url) => {
    const query = qs.parse(url.substring(url.indexOf('?') + 1));
    setSearchState({ query: query.w || '' });
  };

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  const handleSearchClick = () => {
    props.toggleGlobalSearchVisibility(true);
    logAmplitude('Opened Search');
  };

  return (
    <InstantSearch
      searchClient={customSearchClient}
      onSearchStateChange={onSearchStateChange}
      searchState={searchState}
      indexName={process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS_INDEX}
    >
      {/* This is to enable Algolia personlization for logged in user */}
      <Configure
        enablePersonalization={!!store.getState().profile?.id || false}
        userToken={store.getState().profile?.id}
        clickAnalytics
      />
      <RecentProvider>
          <Wrapper id="search-modal" isOpened={isOpened}>
            <SearchBox onFocus={() => !isOpened && handleSearchClick()} />
            <Panel isOpened={isOpened}>
              <Left>
                <RecentSearches mobileOnly />
                <SuggestedResults q={searchQuery} />
                <PopularSearches q={searchQuery} />
              </Left>

              <Right>
                <RecentSearches q={searchQuery} />
                <NoResults q={searchQuery} />
                <Results q={searchQuery} />
                <ReadSearches q={searchQuery} />
              </Right>
            </Panel>
          </Wrapper>
      </RecentProvider>
    </InstantSearch>
  );
};

SearchResultsContainer.propTypes = {
  toggleGlobalSearchVisibility: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isGlobalSearchActive: state.interfaces.isGlobalSearchActive
});

const mapDispatchToProps = (dispatch) => ({
  toggleGlobalSearchVisibility: (isActive) => dispatch(toggleGlobalSearchVisibility(isActive))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsContainer);
